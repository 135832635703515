import context, { getDefaultState } from 'chimera/all/store/context'
import deepmerge from 'deepmerge'

export const state = () => {
  return deepmerge(
    getDefaultState(),
    {
      country: 'es',
      language: 'es'
    }
  )
}

export default {
  ...context,
  state
}
